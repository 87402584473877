import React from "react";
import Layout from "../components/layout";
import HeroImages from "../components/Reusables/HeroImages";
import SEO from "../components/seo";
import Testimonial from "../components/Employers/Testimonial";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
import Accordion from "../components/Reusables/Accordion";
import hero from "../images/pageImages/Investment/Hero.jpg";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
import DescriptionBlock from "../components/Home/DescriptionBlock"
const StyledWrapper = styled.div`
  .apply-now {
    padding: 3% 0;
    background-color: #e43d5a;
  }

  .boxHeight {
    @media (max-width: 600px) {
      height: 250px;
    }
  }

  .accordion-start {
    padding-top: 50px;
  }

  .flat-button {
    color: white;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 43px;
    margin: auto;
    @media (max-width: 600px) {
      font-size: 22px;
    }
  }

  .flat-btn-link {
    margin: auto;
    display: block;
    text-decoration: none;
    text-align: center;
  }

  .flat-btn-link:hover {
    color: white;
  }

  .section-divider {
    margin-top: 50px;
  }

  .margin-top {
    margin-top: 80px;
  }

  .grew-up {
    padding: 3% 0;
    background-color: #5db9db;
    height: 160px;

    @media (max-width: 600px) {
      height: unset;
      padding: 5% 8%;
    }
  }

  .onhover-display {
    color: white;
    font-size: 25px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    display: none;
    @media (max-width: 600px) {
      font-size: 14px;
      line-height: normal;
    }
  }

  .grew-up:hover {
    padding-top: 2%;
  }

  .grew-up:hover .onhover-display {
    display: block;
  }

  .grew-up:hover .flat-button {
    display: none;

    @media (max-width: 600px) {
      display: unset;
    }
  }

  .onhover-links {
    color: white;
  }
  .onhover-links:hover {
    color: white;
  }
  .img-class {
    height: 5rem;
  }
  .eng-spring .subtext{
    font-size: 1rem;
    line-height: 1.5;
  }
`;
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Engineering />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/energyand-net-zero-springboard`);
};
const fetchHeroImage = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/hero-images`);
};

const Engineering = () => {
  const [newData, setNewData] = useState(null);
  const [heroImages, setHeroImages] = useState(null);
  const { data: pageData } = useQuery("NetZeroSpringBoard", fetchData);
  const { data: heroImage } = useQuery(
    "NetZeroSpringboardPageImages",
    fetchHeroImage
  );
  const mainUrl = process.env.GATSBY_API_URL;
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
    if (heroImage !== undefined) {
      setHeroImages(heroImage.data);
    }
  }, [pageData, heroImage]);


  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        {newData && <SEO title={newData.SEOtitle} description={newData.SEOdescription}/>}
        {newData &&
          newData.showHeroImageEneNet &&
          heroImages &&
          heroImages.length > 0 &&
          heroImages.map(
            item =>
              item.Slug.toLowerCase() === "NetZeroSpringboardPage".toLowerCase() &&
              item.Image && <HeroImages heroImage={item.Image.url} />
          )}

        {newData &&
          newData.showENZSpringboardTestimonial &&
          newData.EnergyNetZeroSpringboardTestimonial.length > 0 && (
            <Testimonial carousel={newData.EnergyNetZeroSpringboardTestimonial} />
          )}
        <div className="container-fluid p-0">
          <div className="padding-left-8 padding-right-8 accordion-start">
            {newData && newData.EneNetMainHead && (
              // <ReactMarkdown>{newData.EngMainHead}</ReactMarkdown>
              <div className="eng-spring">
              <DescriptionBlock description={newData.EneNetMainHead}/>
              </div>
            )}
            {newData &&
              newData.showSponsorImageEneNet &&
              newData.sponsorImageEneNet && (
                <img
                  className="img-class"
                  src={mainUrl + newData.sponsorImageEneNet.url}
                  alt="diversitylogo"
                />
              )}
          </div>
          {newData && newData.showEnergyNetZeroAccordian && (
            <Accordion
              accordionHeading={newData.EneNetAccordianHeading.heading}
              accordion={newData.EnergyNetZeroSpringboardAccordion}
            />
          )}
        </div>
      </StyledWrapper>
    </Layout>
  );
};
